import React from "react";
import Content from "../component/Service/Content";
import { content1, content2, content3, content4 } from "../img";
import { Fade } from "react-reveal";
import { Grids } from "../elements/Grid";
import styled from "styled-components";
import { MainTitle } from "../elements/Font";

const content = [
  {
    id: 1,
    title: "근거를 기반으로 목표를 달성하는",
    subTitle: "마케팅 캠페인",
    desc: (
      <>
        퍼즐랩은 이유 없이 일 하지 않습니다. 즉, 퍼즐랩의 모든 일에는 꼭 타당한
        근거가 따르죠.
        <br /> 클라이언트에 대한 깊은 이해와 산업을 꿰뚫는 분석력을 통해 최적의
        목표 달성 방법을 기획합니다.
        <br /> 단기 프로젝트에서 연간 캠페인까지, 퍼즐랩의 기획은 가장 나은
        결과가 됩니다.
      </>
    ),
    path: content1,
    circleW: [
      "브랜드\n캠페인",
      "프로모션 \n 이벤트 \n 캠페인",
      "웹·디지털 \n 캠페인",
    ],
    circleY: {
      title: [
        "CRM",
        "데이터 \n 드리븐",
        "언론 홍보",
        "ATL BTL",
        "시리즈 광고",
        "소셜마케팅",
      ],
      desc: [],
    },
  },
  {
    id: 2,
    title: "핵심을 알리고 가치를 높이는",
    subTitle: "블록체인·디지털자산 액셀러레이팅",
    desc: (
      <>
        블록체인 프로젝트에서 투자자까지, 모두를 만족시키는 디지털자산 특화
        마케팅 커뮤니케이션 서비스를 만나보세요.
        <br /> 퍼즐랩 멤버는 이미 수 많은 탑-티어 디지털자산 프로젝트의 마케팅
        성공사례를 보유하고 있습니다.
        <br /> 또, 프로젝트의 더 나은 비즈니스 계획 및 올바른 방향성 설정을 위해
        통합 네트워크 솔루션을 제공합니다.
      </>
    ),
    path: content2,
    circleW: [
      "커뮤니티\n매니지먼트",
      "....",
      "채널\n구축 및 운영",
      "....",
      "이벤트\n프로모션\n기획 및 운영",
      "....",
      "버티컬·바이럴\n마케팅",
    ],
    circleY: {
      title: ["통합 네트워크 솔루션"],
      desc: ["미디어 릴레이션십\n인플루언서/KOL 네트워크"],
    },
  },
  {
    id: 3,
    title: "작품과 기술을 이어 새로운 가치를 만드는",
    subTitle: "디지털 아트 비즈니스 컨설팅",
    desc: (
      <>
        각종 예술품 및 창작물과 블록체인을 이어 새로운 부가가치를 만듭니다.
        <br />
        새로운 디지털 아트 콘텐츠를 발굴 및 개발하고, NFT로서의 고부가가치를
        부여하여 창작자와 컬렉터 <br />
        모두에게 도움이 되는 통합 비즈니스 컨설팅 서비스를 제공합니다.
      </>
    ),
    path: content3,
    circleW: [
      "IP\n기획/발굴",
      "NFT 기획\n유니버스 빌딩",
      "NF\n 제작 및 발행",
      "커뮤니티\n기획 및 운영",
      "PR\n마케팅",
    ],
    circleY: {
      title: [],
      desc: [],
    },
  },
  {
    id: 4,
    title: "브랜드에겐 기회가, 소비자에겐 길이 되어주는",
    subTitle: "디지털 크리에이티브 AD",
    desc: (
      <>
        단순 영상 광고를 넘어, 빠른 디지털 환경 변화에 발맞춘 새로운 형태의
        인터렉티브 광고 콘텐츠를 기획하고 만듭니다.
        <br /> 퍼즐랩의 디지털 크리에이티브 솔루션과 함께, 브랜드와 프로젝트를
        어떤 미디어 채널에서도 잘 보이게, 또 기억나게,
        <br /> 그리고 다시 찾을 수 있게 만드세요.
      </>
    ),
    path: content4,
    circleW: [
      "TVC",
      "디지털 필름",
      "브랜드 필름",
      "숏폼\n크리에이티브",
      "라이브커머스",
    ],
    circleY: {
      title: [],
      desc: [],
    },
  },
];

const Service = ({ width }) => {
  return (
    <StyleContainer>
      <Container>
        <StyleTitle>
          <Fade top>
            <MainTitle>Service of</MainTitle>
            <MainTitle color={({ theme }) => theme.color.orange}>
              Puzzle lab
            </MainTitle>
          </Fade>
        </StyleTitle>
        {content.map((e) => (
          <Fade bottom key={e.id}>
            <Content
              title={e.title}
              subTitle={e.subTitle}
              desc={e.desc}
              cW={e.circleW}
              cY={e.circleY}
              width={width}
            />
          </Fade>
        ))}
      </Container>
    </StyleContainer>
  );
};

const StyleContainer = styled.section`
  padding: 18.75rem 17% 12.5rem;

  min-height: calc(100vh - 287px);

  @media (max-width: 450px) {
    padding: 18.75rem 6rem 12.5rem;
  }
`;

const Container = styled.div`
  max-width: 1272px;
  margin: 0 auto;
`;

const StyleTitle = styled(Grids)`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
`;

export default Service;
