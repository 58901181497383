import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Footer } from "./component";
import Header from "./component/Layout/Header";
import Contact from "./page/Contact";
import Home from "./page/Home";
import Recruit from "./page/Recruit";
import Service from "./page/Service";

function App() {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const width = window.innerWidth || document.body.clientWidth;

    setWidth(width);
    window.addEventListener("resize", () => {
      const width = window.innerWidth || document.body.clientWidth;
      setWidth(width);
    });
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home width={width} />} />
        <Route path="/service" element={<Service width={width} />} />
        <Route path="/recruit" element={<Recruit width={width} />} />
        <Route path="/contact" element={<Contact width={width} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
