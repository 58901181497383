import React from "react";
import styled from "styled-components";
import { Grid } from "../../elements";
import Circle from "../../elements/Circle";
import { Grids } from "../../elements/Grid";
import CircleWB from "./CircleWB";
import CircleYB from "./CircleYB";

const Content = ({ title, subTitle, desc, cW, cY, width }) => {
  const renderCircleW = (length) => {
    switch (length) {
      case 3:
        return (
          <CircleW mw="53.5rem">
            {cW.map((e, i) => (
              <Circle key={i} color="W" length="3">
                {e}
              </Circle>
            ))}
          </CircleW>
        );
      case 5:
        return (
          <CircleW>
            {cW.map((e, i) => (
              <Circle key={i} color="W">
                {e}
              </Circle>
            ))}
          </CircleW>
        );
      case 7:
        return (
          <CircleW mw="71.7rem">
            {cW.map((e, i) => (
              <Circle key={i} color="W" length="4">
                {e}
              </Circle>
            ))}
          </CircleW>
        );
      default:
        return null;
    }
  };

  return (
    <ContentContainer margin="4.5rem 0">
      <ContentBox>
        <Grid>
          <p>{title}</p>
          <Grid margin="1rem 0 2.5rem">
            <p
              className={
                subTitle === "디지털 크리에이티브 AD" ? "digital" : null
              }
            >
              {subTitle}
            </p>
          </Grid>
          <p>{desc}</p>
        </Grid>

        {cW.length > 0 && width > 800 ? (
          renderCircleW(cW.length)
        ) : (
          <CircleWB cW={cW} />
        )}

        {cY && cY.title.length > 0 && cY.desc.length > 0 ? (
          <>
            <Circle color="plus" />
            <TriangleY>
              <p>{cY.title[0]}</p>
              <p>{cY.desc[0]}</p>
            </TriangleY>
          </>
        ) : (
          <CircleY>
            <CircleYB cY={cY.title} width={width} />
          </CircleY>
        )}
      </ContentBox>
    </ContentContainer>
  );
};
const ContentContainer = styled.article`
  margin-top: 10rem;

  @media (max-width: 450px) {
    margin-top: 7rem;
  }
`;

const ContentBox = styled(Grids)`
  background-color: #f8f8f8;
  padding: 5rem 3.75rem;

  & > div:first-child > p {
    ${({ theme }) => theme.font(32, 400, 38.41, 0.01)}
  }

  & > div:first-child > div > p {
    ${({ theme }) => theme.font(56, 700, 67.21, 0.01)}
  }

  & > div:first-child > p:last-child {
    ${({ theme }) => theme.font(24, 400, 40, 0.03, "#8D8D8D")}
  }

  @media (max-width: 375px) {
    .digital {
      white-space: initial !important;
    }
  }

  @media (max-width: 450px) {
    & > div:first-child > p {
      font-size: 4rem;
      line-height: 4.75rem;
    }
    & > div:first-child > div > p {
      font-size: 7.5rem;
      line-height: 9rem;

      margin-top: 2rem;
      margin-bottom: 6rem;
      max-width: 90%;
    }

    .digital {
      white-space: nowrap;
    }

    & > div:first-child > p:last-child {
      font-size: 3.5rem;
      line-height: 6rem;
    }
  }

  @media (max-width: 320px) {
    gap: 10rem;
  }
`;

const CircleW = styled(Grids)`
  ${({ theme }) => theme.flexBox("space-around", "center", "")}
  max-width: ${(props) => props.mw};
  margin: 4.5rem auto 0;
`;

const CircleY = styled(Grids)`
  width: 100%;
  margin: 4.5rem 0 0;

  ${({ theme }) => theme.flexBox("space-around", "center", "")}

  @media (max-width: 450px) {
    gap: 2rem;
  }
  @media (max-width: 320px) {
    gap: 8rem;
  }
`;

const TriangleY = styled.div`
  width: 44%;

  ${({ theme }) => theme.flexBox("center", "center", "column")}

  margin: 0 auto;
  background: ${({ theme }) => theme.color.garyBg};
  border: 2px solid ${({ theme }) => theme.color.orange};
  border-radius: 500px;

  white-space: pre;

  & > p {
    margin: 0;
  }

  & > p:nth-child(1) {
    ${({ theme }) => theme.font(24, 700, 38, 0.01, theme.color.orange)}
    padding-top: 1.25rem;
    text-align: center;
  }

  & > p:nth-child(2) {
    ${({ theme }) => theme.font(18, 400, 30, 0.01, theme.color.orange)}
    padding: 0.5rem 0 1.5rem;
    text-align: center;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding: 3.5%;
    gap: 8px;
    background: #fff2d1;
    border: 1px solid ${({ theme }) => theme.color.orange};
    border-radius: 71.5rem;

    & > p:nth-child(1) {
      font-size: 14px;
      line-height: 17px;
    }

    & > p:nth-child(2) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default Content;
