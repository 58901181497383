import React from "react";
import styled from "styled-components";
import { Circle } from "../../elements";

const CircleYB = ({ cY, width }) => {
  return (
    <>
      {cY.length > 0 && width < 800 ? (
        <div>
          <CircleGroup>
            <Circle color="Y">{cY[0]}</Circle>
            <Circle color="Y">{cY[1]}</Circle>
            <Circle color="Y">{cY[2]}</Circle>
          </CircleGroup>
          <CircleGroup>
            <Circle color="Y">{cY[3]}</Circle>
            <Circle color="Y">{cY[4]}</Circle>
            <Circle color="Y">{cY[5]}</Circle>
          </CircleGroup>
        </div>
      ) : (
        cY.map((e, i) => (
          <Circle key={i} color="Y">
            {e}
          </Circle>
        ))
      )}
    </>
  );
};

const CircleGroup = styled.div`
  ${({ theme }) => theme.flexBox("center", "center")}
  gap: 2rem;
  margin-bottom: 2rem;
`;

export default CircleYB;
