import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { close } from "../../img";
import { Slide, unSlide } from "../../animation";
import { Portal } from "..";

const Sidebar = ({ handleCloseSidebar, unSlide }) => {
  const navigate = useNavigate();

  const sideItem = [
    { id: 1, menu: "Overview", path: "/" },
    { id: 2, menu: "Service", path: "/service" },
    { id: 3, menu: "Recruit", path: "/recruit" },
    { id: 4, menu: "Contact us", path: "/contact" },
  ];

  const onClick = (e) => {
    navigate(e.path);
    handleCloseSidebar();
  };

  return (
    <Portal>
      <Container>
        <Side ani={unSlide} onMouseLeave={handleCloseSidebar}>
          <CloseBtn onClick={handleCloseSidebar}>
            <img src={close} alt="closeBtn" />
          </CloseBtn>
          <Column>
            {sideItem.map((e) => (
              <SideMenu key={e.id} onClick={() => onClick(e)}>
                {e.menu}
              </SideMenu>
            ))}
          </Column>
        </Side>
      </Container>
    </Portal>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
`;

const CloseBtn = styled.button`
  display: flex;
  margin: 3.5rem 3.5rem 3.5rem auto;
  padding: 0.85rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 450px) {
    width: 5rem;
    height: 5rem;
    margin-bottom: 12rem;
  }
`;

const Column = styled.ul`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7rem;
  margin-left: calc(100% - 76%);

  @media (max-width: 450px) {
    gap: 10rem;
  }
`;

const Side = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - 66%);
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);

  animation: ${(props) => (props.ani ? unSlide : Slide)} 0.5s linear;

  @media (max-width: 450px) {
    width: calc(100% - 29.5%);
  }
`;

const SideMenu = styled.li`
  ${({ theme }) => theme.font(54, 900, 64.81, 0, theme.color.white)}
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.orange};
  }

  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export default Sidebar;
