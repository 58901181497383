import React from "react";
import styled from "styled-components";
import { FadeIn } from "../../animation";
import { Position } from "../../elements";
import { Grids } from "../../elements/Grid";
import { logo } from "../../img";

const Logo = ({ stickyPoint }) => {
  return (
    <Position top={stickyPoint} maxH>
      <Logos flex>
        <img src={logo} alt="logo" />
      </Logos>
    </Position>
  );
};

const Logos = styled(Grids)`
  background: ${({ theme }) => theme.color.orange};
  height: 100%;

  & img {
    animation: ${FadeIn} 2.5s linear;
    padding: 35vh 17% 11.5vh;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 450px) {
    & img {
      padding: 50vh 6rem 8.2vh;
    }
  }
`;

export default Logo;
