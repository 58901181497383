import React from "react";
import styled from "styled-components";
import { Grid } from "../../elements";
import { PZTitle } from "../../elements/Font";

const TextCardLeft = ({ title, subTitle, desc, img }) => {
  return (
    <Grid width="100%">
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Desc>{desc}</Desc>
      {img && img}
    </Grid>
  );
};

const Desc = styled.p`
  word-break: keep-all;
  width: 36.8rem;

  ${({ theme }) => theme.font(28, 400, 48, 0.03, theme.color.brown)}

  @media (max-width: 450px) {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
  }
`;

const Title = styled(PZTitle)`
  @media (max-width: 450px) {
    margin: 40px 0 16px;
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: 280px) {
    word-break: break-word;
    font-size: 30px;
  }
`;

const SubTitle = styled.p`
  padding: 1rem 0 2rem;
  ${({ theme }) => theme.font(40, 700, 48, 0.01)}

  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export default TextCardLeft;
