import React from "react";
import styled, { css } from "styled-components";
import { plus } from "../img";

const Circle = ({ color, children, length }) => {
  switch (color) {
    case "W": {
      if (children === "....") {
        return <Dot> {children}</Dot>;
      } else {
        return <CircleW length={length}> {children}</CircleW>;
      }
    }
    case "Y":
      return <CircleY>{children}</CircleY>;
    case "plus":
      return <CirclePlus path={plus} />;
    default:
      return <CustomCircle>{children}</CustomCircle>;
  }
};

const Dot = styled.div`
  ${({ theme }) => theme.font(24, 700, 38, 0.01, theme.color.orange)}
  white-space: pre-line;
  text-align: center;
`;

export const CircleBox = styled.div`
  margin: 4.5rem 0 0;

  ${({ theme }) => theme.flexBox("center", "center")}
  gap: 4rem;
`;

export const CircleW = styled.div`
  max-width: 12.5rem;
  min-width: 80px;
  position: relative;
  width: 23.5%;

  ${(props) =>
    props.length === "3"
      ? css`
          &::after {
            content: "....";
            position: absolute;
            right: -31%;
          }
        `
      : null}

  &:last-child::after {
    content: none;
  }

  &::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.color.orange};

  ${({ theme }) => theme.flexBox("center", "center")}
  ${({ theme }) => theme.font(24, 700, 38, 0.01, theme.color.orange)}

  white-space: pre-line;
  text-align: center;

  @media (max-width: 1300px) {
    width: 19.5%;

    ${(props) =>
      props.length === "5"
        ? css`
            &::after {
              content: "....";
              position: absolute;
              right: -52%;
            }
          `
        : null}

    ${(props) =>
      props.length === "3"
        ? css`
            &::after {
              content: "....";
              position: absolute;
              right: -47%;
            }
          `
        : null}
  }

  @media (max-width: 800px) {
    border: 1px solid ${({ theme }) => theme.color.orange};
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;

    color: ${({ theme }) => theme.color.orange};

    min-width: 70px;
  }
  @media (max-width: 280px) {
    min-width: 70px;
    font-size: 10px;
  }
`;

export const CircleY = styled.div`
  min-width: 72px;
  width: 15%;

  &::after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }

  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.orange};
  background-color: #fff2d1;

  ${({ theme }) => theme.flexBox("center", "center")}
  ${({ theme }) => theme.font(24, 700, 36, 0.01, theme.color.orange)}

  white-space: pre-line;
  text-align: center;

  @media (max-width: 800px) {
    border: 1px solid ${({ theme }) => theme.color.orange};
    line-height: initial;
    font-size: 11px;
    min-width: 66px;
  }

  @media (max-width: 280px) {
    min-width: 60px;
    line-height: initial;
    font-size: 10px;
  }
`;

export const CustomCircle = styled.span`
  color: ${({ theme }) => theme.color.orange};
`;

const CirclePlus = styled.div`
  background: url(${(props) => props.path});
  --size: 40px;
  width: var(--size);
  height: var(--size);

  margin: 1.2rem auto 1.7rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 800px) {
    margin-top: 0.5rem;
    margin-bottom: 4rem;

    --size: 26px;
    width: var(--size);
    height: var(--size);
  }
`;

export default Circle;
