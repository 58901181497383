import styled from "styled-components";

export const MainTitle = styled.h1`
  ${({ theme }) => theme.staticFont.Title}
  color: ${(props) => props.color || "#000"};

  @media (max-width: 450px) {
    font-size: 10.5rem;
    line-height: 12.5rem;
  }
`;

export const PZTitle = styled.h1`
  ${({ theme }) => theme.font(72, 900, 86, 0.01)}
  color: ${(props) => props.color};

  @media (max-width: 450px) {
    font-size: 8rem;
    line-height: 9.5rem;
  }
`;
