import React from "react";
import styled from "styled-components";

const Bg = ({ children, path, size }) => {
  const styles = { size };

  return (
    <Back {...styles} src={path}>
      {children}
    </Back>
  );
};

Bg.defaultProps = {
  src: "",
  size: "",
};

export const Back = styled.div`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: ${(props) => props.size};
  position: relative;
`;

export default Bg;
