import { keyframes } from "styled-components";

export const Slide = keyframes`
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(100)
  }
`;

export const unSlide = keyframes`
  0% {
    transform: translateX(100)
  }
  100% {
    transform: translateX(100%)
  }
`;

export const FadeIn = keyframes`
 0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
 0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;

export const dropPuzzle = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    transform: translateX(28%);
    opacity: 1;
  }
`;
export const mobileDropPuzzle = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    transform: translateX(42%);
    opacity: 1;
  }
`;

export const crossPuzzle = keyframes`
  0%{
      transform: translateX(0);
  }
  50%{
      transform: translateX(0);
  }
  75% {
    transform: translateX(-15%);
  }

`;

export const mobileCrossPuzzle = keyframes`
  0%{
      transform: translateX(0);
  }
  50%{
      transform: translateX(0);
  }
  75% {
    transform: translateX(-30%);
  }

`;
