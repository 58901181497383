import styled, { css } from "styled-components";

import React from "react";

const Position = (props) => {
  const { bg, maxH } = props;

  const styled = { bg, maxH };

  return <FixPosition {...styled}>{props.children}</FixPosition>;
};

const FixPosition = styled.section`
  position: sticky;
  top: 0;
  background: ${(props) => props.bg};
  ${(props) =>
    props.maxH
      ? css`
          height: 100vh;
        `
      : null}
`;

export default Position;
