import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Sidebar } from "..";
import { sidebar, sidebarW } from "../../img";

const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [unSlide, setUnSlide] = useState(false);

  const handleCloseSidebar = () => {
    setUnSlide(true);

    setTimeout(() => {
      setShowSideBar(false);
      setUnSlide(false);
    }, 500);
  };

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      {showSideBar && (
        <Sidebar handleCloseSidebar={handleCloseSidebar} unSlide={unSlide} />
      )}
      <Container>
        <SideButton onMouseOver={(e) => setShowSideBar(true)}>
          {!showSideBar && (
            <img
              src={pathname === "/contact" ? sidebarW : sidebar}
              alt="sidebar"
            />
          )}
        </SideButton>
      </Container>
    </>
  );
};

const Container = styled.header`
  z-index: 22;
  position: fixed;
  top: 1.5rem;
  right: 15%;

  @media (max-width: 450px) {
    right: 3%;
  }
`;

const SideButton = styled.button`
  width: 48px;
  border: none;
  background: transparent;

  @media (max-width: 800px) {
    width: 32px;
  }
`;

export default Header;
