import React from "react";
import styled from "styled-components";
import { Grids } from "../../elements/Grid";
import { TextDirection } from "../../elements/TextBox";

const TextCard = ({ title, desc, hash, position, mobileDesc, width }) => {
  return (
    <StageContainer right={position === "right"}>
      <StageText>
        <Title>{title}</Title>
        <p>{width && width > 450 ? desc : mobileDesc}</p>
        <p>{hash}</p>
      </StageText>
    </StageContainer>
  );
};

const StageContainer = styled(TextDirection)`
  @media (max-width: 450px) {
    margin-top: 4rem;
    margin-bottom: 18rem;
  }
`;

const StageText = styled(Grids)`
  & > p:nth-child(2) {
    ${({ theme }) => theme.font(28, 400, 48, 0.03, theme.color.white)}
    margin:3rem 0 1.5rem;
  }

  & > p:last-child {
    ${({ theme }) => theme.font(24, 400, 28.8, 0, theme.color.darkGray)}
  }

  @media (max-width: 450px) {
    & > p:nth-child(2) {
      font-size: 16px;
      line-height: 26px;
    }

    & > p:last-child {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const Title = styled.span`
  ${({ theme }) => theme.font(64, 800, 76, 0.015, theme.color.orange)}

  @media (max-width: 450px) {
    position: relative;

    font-size: 28px;
    line-height: 34px;
  }
`;

export default TextCard;
