import React from "react";
import styled from "styled-components";
import { Grid, Img } from "../elements";
import { Grids } from "../elements/Grid";
import { recruitBg, recruit_1, recruit_2, arrow } from "../img";
import { Fade } from "react-reveal";
import { MainTitle } from "../elements/Font";
import { RecruitHash, RecruitText } from "../component";

const RecruitContent = [
  {
    id: 1,
    title: "퍼즐랩의 새로운 트라이어(Trier)가 되어주세요",
    desc: (
      <>
        퍼즐랩의 트라이어가 되어 함께 꿈 꾸고 노력하며, 그에 따른 성장의 기회와
        좋은 보상을 가지세요.
        <br /> 인턴십 및 다양한 온보딩 프로그램을 통해 각자의 능력에 알맞은
        포지션을 매칭하고 스킬을 개발하며 즐겁게 일할 수 있도록 지원합니다.
      </>
    ),
    path: recruit_1,
  },
  {
    id: 2,
    title: "퍼즐랩이 희망하는 동료상이에요",
    desc: <RecruitHash />,
    path: recruit_2,
  },
];

const Recruit = () => {
  return (
    <RecruitContainer path={recruitBg}>
      <Container>
        <Fade top>
          <Grid>
            <MainTitle>Recruit</MainTitle>
          </Grid>
          <PuzzleTextBox>
            <p>[ 퍼즐랩은 </p>
            <p>창의적인 실험가 </p>
            <p>들의 노크를 언제든지 환영합니다 ]</p>
          </PuzzleTextBox>
        </Fade>
        <Grid margin="0 0 2.5rem 0">
          {RecruitContent.map((e, i) => (
            <Fade bottom key={e.id}>
              <RecruitText title={e.title} desc={e.desc} path={e.path} />
            </Fade>
          ))}
        </Grid>

        <Grid flex justEnd>
          <RecruitBtn>
            <a
              // href="https://www.jobkorea.co.kr/Recruit/GI_Read/38488813?Oem_Code=C1&logpath=1&stext=%ED%8D%BC%EC%A6%90%EB%9E%A9&listno=2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>채용공고 확인</p>
              <img src={arrow} alt="arrow" />
            </a>
          </RecruitBtn>
        </Grid>
      </Container>
    </RecruitContainer>
  );
};

const RecruitContainer = styled.section`
  background-image: url(${(props) => props.path});

  padding: 296px 17% 200px;
  min-height: calc(100vh - 287px);
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 450px) {
    padding: 120px 6rem 80px;
  }

  @media (max-width: 1084px) {
  }
`;

const Container = styled.div`
  max-width: 1257px;
  margin: 0 auto;
`;

const PuzzleTextBox = styled(Grids)`
  margin: 9.5rem 0 5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & p {
    ${({ theme }) => theme.font(54, 500, 64.81, 0.04)}
  }
  & p:nth-child(odd) {
    color: ${({ theme }) => theme.color.white};
  }

  & > p:nth-child(2) {
    font-weight: ${({ theme }) => theme.fw.extraBold};
    padding: 0 0.5rem;
  }

  @media (max-width: 450px) {
    & > p {
      font-size: 6rem;
      line-height: 7.5rem;
    }

    & > p:last-child {
      white-space: pre;
      margin-top: 2rem;
    }
  }

  @media (max-width: 350px) {
    & > p:last-child {
      white-space: pre-wrap;
    }
  }
`;

const RecruitBtn = styled.button`
  & a {
    display: flex;
  }

  & p {
    ${({ theme }) => theme.font(24, 700, 28.8, 0.045)}
  }

  padding: 1.375rem 1.5rem 1.375rem 2.5rem;

  ${({ theme }) => theme.flexBox("center", "center")}
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.black};

  & img {
    width: 1.5rem;
    margin-left: 5.5rem;
    padding: 0.25rem;
  }

  &:hover {
    & p,
    img {
      ${({ theme }) => theme.color.orange};
    }

    background-color: ${({ theme }) => theme.color.black};
  }

  cursor: pointer;

  @media (max-width: 450px) {
    & p {
      font-size: 3.5rem;
      line-height: 4.25rem;
    }

    padding: 3.5rem 5.5rem;

    & img {
      margin-left: 6rem;
      padding: 0.5em;
      --size: 4rem;
      width: var(--size);
      height: var(--size);
    }
  }
`;

export default Recruit;
