import React from "react";
import styled from "styled-components";
import { Grid } from "../../elements";
import { Grids } from "../../elements/Grid";

const RecruitText = ({ title, desc, path }) => {
  return (
    <RecruitTextContainer>
      <ImgBox>
        <img src={path} alt="path" />
      </ImgBox>
      <RecruitTextBox>
        <Grid margin="1.5rem 0">
          <p>{title}</p>
        </Grid>
        <Grid>
          <span>{desc}</span>
        </Grid>
      </RecruitTextBox>
    </RecruitTextContainer>
  );
};

const ImgBox = styled.div`
  width: 100%;
  max-width: 5rem;
`;

const RecruitTextContainer = styled.article`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;

    & > div:nth-child(1) {
      margin-bottom: 3rem;
    }

    & > div > img {
      --size: 10rem;
      width: var(--size);
    }
  }
`;

const RecruitTextBox = styled(Grids)`
  margin-left: 1.75rem;

  & > div:nth-child(1) > p {
    ${({ theme }) => theme.font(40, 700, 48.01, 0.01)}
  }

  & > div:nth-child(2) {
    margin-bottom: 4rem;

    & span {
      ${({ theme }) => theme.font(28, 400, 48, 0.03, theme.color.brown)}
    }
  }

  @media (max-width: 450px) {
    & > div:nth-child(1) > p {
      font-size: 6rem;
      line-height: 9rem;
    }

    & > div:nth-child(2) {
      margin-bottom: 9rem;

      p {
        font-size: 3.5rem;
        line-height: 6rem;

        word-break: keep-all;
        max-width: 85%;
      }
    }

    & > div:nth-child(2) > p > br {
      display: none;
    }
  }
`;

export default RecruitText;
