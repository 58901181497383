import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DropPuzzle,
  StageContent,
  Logo,
  Organization,
  PuzzleContent,
} from "../component/Home/";

const Home = ({ width }) => {
  const [animation, setAnimation] = useState(false);
  const [stickyPoint, setStickyPoint] = useState(0);

  const puzzleRef = useRef(null);
  const alphabetRef = useRef(null);

  const scrollAnimation = useCallback(() => {
    let scrollY = document.documentElement.scrollTop || window.scrollY;
    let windowHeight = window.innerHeight;

    let puzzleDrop = puzzleRef?.current?.getBoundingClientRect().top;
    let alphabetAt = alphabetRef?.current?.getBoundingClientRect() || 0;

    const { bottom, y } = alphabetAt;

    if (scrollY > puzzleDrop) {
      setAnimation(true);
    }

    const stickyH = windowHeight - bottom;

    if (Math.sign(stickyH) !== -1) {
      setStickyPoint(y);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollAnimation);

    return () => window.removeEventListener("scroll", scrollAnimation);
  }, [scrollAnimation]);

  return (
    <>
      <Logo stickyPoint={stickyPoint} />
      <DropPuzzle ref={puzzleRef} animation={animation} />
      <PuzzleContent
        ref={alphabetRef}
        width={width}
        stickyPoint={stickyPoint}
      />
      <Organization />
      <StageContent width={width} />
    </>
  );
};

export default React.memo(Home);
