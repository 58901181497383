import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import { Grid, Position } from "../../elements";
import { Grids } from "../../elements/Grid";
import { organi1, organi2 } from "../../img";
import { theme } from "../theme";

const Organization = () => {
  return (
    <Position bg={theme.color.white}>
      <Content>
        <Fade top>
          <p>Organization</p>
        </Fade>
        <Grid>
          <Grid margin="3rem auto 3rem 0" width="66%">
            <Fade left>
              <img src={organi1} alt="organi1" />
            </Fade>
          </Grid>
          <Grid width="66%" margin="0 0 0 auto">
            <Fade right>
              <img src={organi2} alt="organi2" />
            </Fade>
          </Grid>
        </Grid>
      </Content>
    </Position>
  );
};

const Content = styled(Grids)`
  padding: 18.5vh 17vw 20vh;

  ${({ theme }) => theme.flexBox("center", "", "column")}

  & p {
    ${({ theme }) => theme.font(72, 800, 86, 0.015, theme.color.orange)}
  }

  @media screen and (min-device-aspect-ratio: 16 / 9) and (max-height: 800px) {
    padding: 7vh 17vw;
  }

  @media (max-width: 450px) {
    padding: 12rem 6rem 18rem;

    & p {
      font-size: 8rem;
      line-height: 9.5rem;
    }

    & div:nth-child(2) {
      & > div {
        width: 100%;
      }
    }
  }
`;

export default Organization;
