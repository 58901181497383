import React from "react";
import styled from "styled-components";
import { Grid } from "../../elements";
import { Grids } from "../../elements/Grid";

const HashContent = [
  {
    id: 1,
    title: "정체성",
    hash: [
      "#즐거운혁신가",
      "#현명한커뮤니케이터",
      "#윤리적사회구성원",
      "#타당한기획자",
      "#효율적실행자",
      "#적극적참여자",
      "#냉철한분석자",
    ],
  },
  {
    id: 2,
    title: "행동 및 가치",
    hash: [
      "#상호존중",
      "#긍정마인드",
      "#신속한판단",
      "#정확한디렉션",
      "#검없는실행",
      "#담대한보고",
      "#깔끔한뒷처리",
      "#마땅한리프레시",
      "#내부정치박멸",
      "#의견묵살금지",
      "#쇼윈도친분추방",
      "#선택과집중",
      "#멀티와플렉서블",
      "#전문성",
      "#넓은시야",
    ],
  },
];

const RecruitHash = () => {
  return (
    <Grid>
      <Grid>
        {HashContent.map((e, i) => (
          <HashBox key={i}>
            <Grid>
              <p>{e.title}</p>
            </Grid>
            <Wrap>
              {e.hash.map((e, i) => (
                <p key={i}>{e}</p>
              ))}
            </Wrap>
          </HashBox>
        ))}
      </Grid>
    </Grid>
  );
};

const HashBox = styled(Grids)`
  margin: 2.5rem 0;

  & > div:nth-child(1) > p {
    ${({ theme }) => theme.font(30, 700, 36.01, 0.01)}
  }

  & > div:nth-child(2) > p {
    ${({ theme }) => theme.font(28, 400, 48, 0.03, theme.color.brown)}
    padding: 0.25rem 1.2rem 0 0;
  }

  @media (max-width: 450px) {
    margin-top: 2.5rem;
    margin-bottom: 2rem;

    & > div:nth-child(1) > p {
      font-size: 3.5rem;
      line-height: 4.25rem;
    }

    & > div:nth-child(2) {
      margin-bottom: 6rem;

      & p {
        font-size: 3.5rem;
        line-height: 6rem;
        padding: 0.25rem 1.2rem 0 0;
      }
    }
  }
`;

const Wrap = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-top: 1rem;
`;

export default RecruitHash;
