import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import {
  crossPuzzle,
  dropPuzzle,
  mobileCrossPuzzle,
  mobileDropPuzzle,
} from "../../animation";
import { Position } from "../../elements";
import { Grids } from "../../elements/Grid";
import { dot, puzzleY, puzzleW } from "../../img";
import { theme } from "../theme";

const DropPuzzle = ({ animation }, ref) => {
  return (
    <Position bg={theme.color.white} maxH>
      <PDContainer ref={ref}>
        <PDText>
          <p>The super last piece</p>
          <p>for your Puzzle</p>
        </PDText>
        <PDDot>
          <img src={dot} alt="dot" />
        </PDDot>
        <PuzzleDrop ani={animation}>
          <img src={puzzleY} alt="puzzleY" />
          <img src={puzzleW} alt="puzzleW" />
        </PuzzleDrop>
      </PDContainer>
    </Position>
  );
};

const PDContainer = styled(Grids)`
  ${({ theme }) => theme.flexBox("center", "center", "column")}
  height: 100%;

  @media (max-width: 450px) {
    margin: 0 auto;
  }

  @media screen and (min-device-aspect-ratio: 16 / 9) and (max-height: 800px) {
    padding: 7vh 0;
  }
`;

const PDText = styled(Grids)`
  & p {
    ${({ theme }) => theme.font(36, 700, 54)}
    text-align: center;
  }

  & p:last-child {
    font-weight: ${({ theme }) => theme.fw.bold};
  }

  @media (max-width: 1600px) {
    & p {
      font-size: 24px;
    }
  }
`;

const PDDot = styled(Grids)`
  display: flex;
  margin: 6vh 0;
  max-height: 248px;

  @media (max-width: 450px) {
    max-height: 152px;
  }
`;

const PuzzleDrop = styled(Grids)`
  display: flex;
  justify-content: center;

  & > img:first-child {
    ${(props) =>
      props.ani
        ? css`
            transform: translateX(28%);
            animation: ${dropPuzzle} 3s infinite;
          `
        : null}
    &:hover {
      z-index: 3;
    }
  }
  & > img:last-child {
    ${(props) =>
      props.ani
        ? css`
            transform: translateX(-15%);
            animation: ${crossPuzzle} 3s infinite;
          `
        : null}
    &:hover {
      z-index: 3;
    }
  }

  @media (max-width: 450px) {
    max-width: 245px;

    & img {
      width: 50%;
    }

    & > img:first-child {
      ${(props) =>
        props.ani
          ? css`
              transform: translateX(42%);
              animation: ${mobileDropPuzzle} 3s infinite;
            `
          : null}
      &:hover {
        z-index: 3;
      }
    }
    & > img:last-child {
      ${(props) =>
        props.ani
          ? css`
              transform: translateX(-30%);
              animation: ${mobileCrossPuzzle} 3s infinite;
            `
          : null}
      &:hover {
        z-index: 3;
      }
    }
  }
`;

export default forwardRef(DropPuzzle);
