import React from "react";
import styled from "styled-components";
import { Circle } from "../../elements";
import { CircleBox } from "../../elements/Circle";
import { Grids } from "../../elements/Grid";

const CircleWB = ({ cW }) => {
  return (
    <>
      {cW.length === 3 && (
        <CircleThree>
          {cW.map((e, i) => (
            <Circle key={i} color="W">
              {e}
            </Circle>
          ))}
        </CircleThree>
      )}

      {cW.length === 5 && (
        <CircleFive>
          <CircleGroup>
            <Circle color="W">{cW[0]}</Circle>
            <Circle color="W">{cW[1]}</Circle>
            <Circle color="W">{cW[2]}</Circle>
          </CircleGroup>
          <CircleGroup>
            <Circle color="W">{cW[3]}</Circle>
            <Circle color="W">{cW[4]}</Circle>
          </CircleGroup>
        </CircleFive>
      )}

      {cW.length === 7 && (
        <CircleSeven>
          <Position>
            <CircleGroup>
              <Circle color="W">{cW[0]}</Circle>
              <Circle color="W">{cW[2]}</Circle>
            </CircleGroup>
            <Dot>....</Dot>
            <CircleGroup>
              <Circle color="W">{cW[4]}</Circle>
              <Circle color="W">{cW[6]}</Circle>
            </CircleGroup>
          </Position>
        </CircleSeven>
      )}
    </>
  );
};

const Position = styled(Grids)`
  position: relative;
`;

const Dot = styled(Grids)`
  position: absolute;
  right: 15%;
  top: 46%;
  transform: rotate(90deg);
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.orange};
  font-weight: ${({ theme }) => theme.fw.bold};
  text-align: center;
`;

const CircleThree = styled(CircleBox)`
  & > div::after {
    content: "....";
    position: absolute;
    right: -21%;
    top: 25px;
  }
  & > div::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  & > div:last-child::after {
    content: none;
  }

  @media screen and (min-width: 450px) and (max-width: 800px) {
    & > div::after {
      content: "....";
      position: absolute;
      right: -18px;
      top: 30px;
    }
  }

  @media (max-width: 320px) {
    gap: 10rem;

    & > div::after {
      content: "....";
      position: absolute;
      right: -16px;
      top: 22px;
    }
  }
`;

const CircleSeven = styled(CircleBox)`
  & > div > div {
    gap: 6rem;
  }

  & > div > div:last-child {
    margin-top: 6rem;
  }

  & > div > div > div::after {
    content: "....";
    position: absolute;
    right: -27%;
    top: 25px;
  }
  & > div > div > div::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  & > div > div > div:last-child::after {
    content: none;
  }

  @media screen and (min-width: 450px) and (max-width: 800px) {
    & > div > div > div::after {
      content: "....";
      position: absolute;
      right: -25px;
      top: 27px;
    }
  }
  @media (max-width: 320px) {
    & > div > div {
      gap: 8rem;
    }

    & > div > div > div::after {
      content: "....";
      position: absolute;
      right: -14px;
      top: 26px;
    }

    & > div > div:last-child {
      margin-top: 10rem;
    }
  }
`;

const CircleGroup = styled.div`
  display: flex;
  gap: 2rem;
`;

const CircleFive = styled(CircleBox)`
  flex-direction: column;
`;

export default CircleWB;
