import React, { forwardRef } from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import { TextCardLeft } from "..";
import { Bg, Grid } from "../../elements";
import { Back } from "../../elements/Bg";
import { PZTitle } from "../../elements/Font";
import { Grids } from "../../elements/Grid";
import { alphabet, butter, puzzleBg, triangle } from "../../img";

const PuzzleContent = ({ width, stickyPoint }, ref) => {
  return (
    <Position ref={ref} width={width} top={stickyPoint}>
      <Bg path={puzzleBg} size="cover">
        <BgAlphabet />
        <PuzzleContentBox padding="11.5rem 0 12rem" margin="0 17%">
          <ContentAnimation>
            <Fade bottom>
              <Title>
                고객에게는 목표를 달성하는 원스톱 마케팅 통합 솔루션을
              </Title>
            </Fade>
            <Fade bottom>
              <Title>
                소비자에게는 기억에 남는 디지털 경험을 제공하기 위해
              </Title>
            </Fade>
            <Column column>
              <Fade bottom>
                <SubTitle>마케팅 각 분야의</SubTitle>
                <SubTitle>전문가들이 모여</SubTitle>
                <PuzzleText flex>
                  <PuzzleLapBlack>퍼즐랩</PuzzleLapBlack>
                  <SubTitle>을 만들었습니다.</SubTitle>
                </PuzzleText>
              </Fade>
            </Column>
          </ContentAnimation>
          <Grid margin="20.5rem 0 24.5rem 0" column gap="24rem">
            <Fade bottom>
              <PuzzleImgContent>
                <TextCardLeft
                  width="49%"
                  title="LIKE A BUTTER !"
                  desc={
                    <>
                      음식에 잘 녹아 적절히 스며든 버터는 그 완성도를 극대화
                      합니다. 이처럼 퍼즐랩은 클라이언트의 산업군에 깊이
                      스며들어 클라이언트의 비즈니스에 가장 알맞은 인터랙티브
                      마케팅 전략을 제시합니다.
                    </>
                  }
                />
                <PuzzleContentImg>
                  <img src={butter} alt="butter" />
                </PuzzleContentImg>
              </PuzzleImgContent>
            </Fade>
            <Grid>
              <Fade bottom>
                <PuzzleImgContent>
                  <Grid>
                    <img src={triangle} alt="triangle" />
                  </Grid>
                  <Key width="40%">
                    <PZTitle>KEY</PZTitle>
                  </Key>
                </PuzzleImgContent>
              </Fade>
            </Grid>
          </Grid>

          <Fade left>
            <TextCardLeft
              title="COMPETITIVENESS"
              subTitle="퍼즐랩의 경쟁력"
              desc={
                <>
                  세상이 바로 우리의 마케팅 툴 입니다. 따라서 영역, 기기, 형태에
                  국한하지 않은 목표달성형 마케팅 캠페인을 기획하고 집행합니다.
                  <br /> 브랜드 론칭에서 확산으로, 상품 노출에서 판매 촉진으로,
                  신규 고객을 충성 고객으로 ‘전환’하는 일,
                  <br /> 퍼즐랩이 가장 잘 하는 일 입니다.
                </>
              }
            />
          </Fade>
        </PuzzleContentBox>
      </Bg>
    </Position>
  );
};

const Column = styled(Grids)`
  padding: 2.5rem 0 0 0;
`;

const Position = styled.section`
  position: sticky;
  top: ${(props) => (props.top ? `${props.top}px` : null)};
`;

const Title = styled.p`
  ${({ theme }) => theme.font(48, 400, 64, 0.03, theme.color.darkBrown)}

  @media (max-width: 450px) {
    width: 236px;

    font-size: 5.5rem;
    line-height: 9rem;
  }
`;

const SubTitle = styled.p`
  ${({ theme }) => theme.font(120, 700, 144, 0.045)}

  @media (max-width:450px) {
    font-size: 10.5rem;
    line-height: 12.5rem;
  }
`;

const PuzzleContentBox = styled(Grids)`
  margin: 0 17%;
  padding: 11.5rem 0 12rem;

  @media (max-width: 450px) {
    margin: 0 6rem;
    padding: 14rem 0 18rem;

    & > div:nth-child(1) {
      & > div:not(:last-child) {
        max-width: 236px;
        margin-bottom: 4rem;
      }

      & > div:nth-child(3) {
        max-width: 260px;
        margin: 10rem 0 18rem;
        padding: 0;
        white-space: pre;
      }
    }
  }
`;

const PuzzleImgContent = styled(Grids)`
  ${({ theme }) => theme.flexBox("space-between", "", "")}

  @media (max-width: 450px) {
    ${({ theme }) => theme.flexBox("", "center", "column-reverse")}
  }
`;

const PuzzleLapBlack = styled.span`
  ${({ theme }) => theme.font(120, 800, 144, 0.045, theme.color.orange)}

  background-color: ${({ theme }) => theme.color.black};
  width: fit-content;
  white-space: nowrap;
  padding: 1px 7px;

  @media (max-width: 450px) {
    margin: 8px 0;
    padding: 2.5rem 2.5rem 1.5rem;
    font-size: 10.5rem;

    ${({ theme }) => theme.color.orange};
    width: fit-content;
  }
`;

const BgAlphabet = styled(Back)`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-image: url(${alphabet});
  background-repeat: no-repeat;
  background-position-y: bottom;

  position: absolute;
  bottom: 0;
  right: 0;

  background-position: center center;
  background-size: contain;
`;

const PuzzleText = styled(Grids)`
  width: max-content;

  @media (max-width: 1084px) {
    display: grid;
  }
`;

const ContentAnimation = styled(Grids)`
  & > div:not(:last-child) {
    max-width: 41.7%;
    word-break: keep-all;
    white-space: pre-line;
    margin-bottom: 2.5rem;
  }
`;

const PuzzleContentImg = styled(Grids)`
  ${({ theme }) => theme.flexBox("center", "center")}
  width: 38%;

  @media (max-width: 450px) {
    width: 100%;
    max-width: 260px;
    margin-bottom: 10rem;
  }
`;

const Key = styled(Grids)`
  ${({ theme }) => theme.flexBox("center", "center")}

  @media (max-width: 450px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10rem;
  }
`;

export default forwardRef(PuzzleContent);
