import React, { useEffect } from "react";
import { marker } from "../../img";
import "./Map.modules.css";

const Map = () => {
  const KEY = process.env.REACT_APP_MAP_KEY;

  useEffect(() => {
    const { kakao } = window;

    const mapScript = document.createElement("script");

    const lat = 37.50598;
    const long = 127.050122;

    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${KEY}&autoload=false`;

    document.head.appendChild(mapScript);

    const onLoadKaKaoMap = () => {
      kakao.maps.load(() => {
        const mapContainer = document.getElementById("map"),
          mapOption = {
            center: new kakao.maps.LatLng(lat, long),
            level: 3,
          };

        const map = new kakao.maps.Map(mapContainer, mapOption);

        var polygonPath = [
          new kakao.maps.LatLng(lat + 0.00005, long - 0.00014),
          new kakao.maps.LatLng(lat - 0.000099, long - 0.000065),
          new kakao.maps.LatLng(lat - 0.00003, long + 0.00012),
          new kakao.maps.LatLng(lat + 0.00011, long + 0.000045),
        ];

        var polygon = new kakao.maps.Polygon({
          path: polygonPath,
          strokeWeight: 3,
          strokeColor: "#F008",
          strokeOpacity: 0.8,
          strokeStyle: "solid",
          fillColor: "#F008",
          fillOpacity: 0.7,
        });

        polygon.setMap(map);

        var mouseoverOption = {
          fillColor: "#F005",
        };

        var mouseoutOption = {
          fillColor: "#F008",
        };

        kakao.maps.event.addListener(polygon, "mouseover", function () {
          polygon.setOptions(mouseoverOption);
        });

        kakao.maps.event.addListener(polygon, "mouseout", function () {
          polygon.setOptions(mouseoutOption);
        });

        var content =
          '<div class ="container">' +
          `<img class='marker' src="${marker}" />` +
          '<span class="address">서울특별시 강남구 테헤란로63길 11</span></div>';

        var position = new kakao.maps.LatLng(lat + 0.00015, long);

        var customOverlay = new kakao.maps.CustomOverlay({
          position: position,
          content: content,
        });

        customOverlay.setMap(map);
      });
    };

    mapScript.addEventListener("load", onLoadKaKaoMap);

    return () => mapScript.removeEventListener("load", onLoadKaKaoMap);
  }, []);

  return (
    <div
      id="map"
      style={{ width: "100%", height: "100%", paddingBottom: "100%" }}
    ></div>
  );
};

export default Map;
