const calcRem = (size) => `${size / 16}rem`;
const calcLH = (size, lh) => `${lh / size}`;

const font = (
  size = "16",
  weight = "400",
  lh = "0",
  ls = "0",
  color = "#000"
) => {
  return `
  font-size:${calcRem(size)};
  font-weight:${weight};
  line-height:${calcLH(size, lh)};
  letter-spacing:-${ls}em;
  color:${color};`;
};

const flexBox = (justify = "center", align = "center", flex = "") => {
  return `
   display: flex;
  justify-content: ${justify};
  align-items:${align};
  flex-direction: ${flex};
  `;
};

const staticFont = {
  Title: {
    "font-size": `${calcRem(120)};`,
    "font-weight": "900",
    "line-height": "1.1",
    "letter-spacing": "-0.01em",
  },
};

const fs = {
  sm: calcRem(14),
  basic: calcRem(16),
  lg: calcRem(36),
  xl: calcRem(48),
  xxl: calcRem(72),
  xxxl: calcRem(120),
};

const fw = {
  md: 500,
  bold: 700,
  extraBold: 800,
  black: 900,
};

const lh = {
  lg: 1.2,
  xl: 1.3,
  xxl: 1.5,
};

const ls = {
  small: "",
  basic: "",
};

const color = {
  black: "#000",
  orange: "#febc18",
  white: "#fff",
  darkBrown: "##4D3907",
  brown: "#70530b",
  gray: "#ffffff99",
  garyBg: "#fff2d1",
  darkGray: "#A8A8A8",
  lightGray: "#8d8d8d",
};

const deviceSizes = {
  laptop: "1024px",
};

const device = {
  laptop: `@media (max-width: ${deviceSizes.laptop})`,
};

export const theme = {
  staticFont,
  font,
  flexBox,
  fs,
  fw,
  lh,
  ls,
  color,
  device,
  deviceSizes,
};
