import React from "react";
import styled from "styled-components";
import { Grid, Img } from "../../elements";
import { Grids } from "../../elements/Grid";
import logoSm from "../../img/logo-sm.svg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Grid>
          <img src={logoSm} alt="logoSm" />
        </Grid>
        <CompanyInfo textCenter>
          <Grid>
            <p>Contact: contact@puzzlelab.kr</p>
          </Grid>
          <Grid>
            <p>Office: Teheran-ro, Gangnam-gu, Seoul, Korea (06162)</p>
          </Grid>
        </CompanyInfo>
        <p>© Puzzle lab. All rights reserved.</p>
      </FooterContent>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  position: absolute;
  width: 100%;
  min-width: 280px;
  background-color: ${({ theme }) => theme.color.black};
  padding: 64px 0;

  @media (max-width: 450px) {
    padding: 48px 6rem;
  }
`;

const FooterContent = styled.div`
  ${({ theme }) => theme.flexBox("center", "center", "column")}

  & > p {
    ${({ theme }) => theme.font(16, 600, 19.2, 0, theme.color.white)}
  }

  @media (max-width: 450px) {
    ${({ theme }) => theme.flexBox("center", "flex-start", "")}

    & img {
      width: 66%;
    }

    & > p {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
`;

const CompanyInfo = styled(Grids)`
  margin: 16px 17% 24px;

  & p {
    ${({ theme }) => theme.font(16, 400, 28, 0, theme.color.white)}
  }

  @media (max-width: 450px) {
    margin: 24px 0 8px;
    text-align: initial;

    & p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default Footer;
