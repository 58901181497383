import React from "react";
import styled, { css } from "styled-components";

const Grid = ({
  children,
  padding,
  margin,
  width,
  height,
  center,
  flex,
  column,
  textCenter,
  onClick,
  justEnd,
  gap,
  between,
  container,
  pointer,
}) => {
  const styles = {
    padding,
    margin,
    width,
    height,
    center,
    flex,
    column,
    textCenter,
    justEnd,
    gap,
    between,
    container,
    pointer,
  };

  return (
    <Grids {...styles} onClick={onClick}>
      {children}
    </Grids>
  );
};

Grid.defaultProps = {
  width: null,
  height: null,
  margin: null,
  padding: null,
  gap: null,
};

export const Grids = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};

  ${(props) => props.flex && "display:flex;"}
  ${(props) => (props.justEnd ? "justify-content: flex-end;" : "")}
  ${(props) => (props.column ? "display:flex; flex-direction: column;" : "")}
  ${(props) => (props.textCenter ? "text-align: center;" : "")}
  ${(props) =>
    props.between ? "display:flex; justify-content: space-between;" : ""};
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

export default Grid;
