import React from "react";
import { Fade } from "react-reveal";
import styled from "styled-components";
import { TextCard } from "..";
import { Bg, Grid, Position } from "../../elements";
import { Grids } from "../../elements/Grid";
import { bgBlack, stageDot } from "../../img";

const StageContent = ({ width }) => {
  return (
    <Position>
      <Bg path={bgBlack} size="cover">
        <Content>
          <Grid margin="0 0 1rem">
            <Fade top>
              <Title>STAGE</Title>
            </Fade>
          </Grid>
          <Fade bottom>
            <TextCard
              width={width}
              title={
                <>
                  Techfin + Blockchain + Digital Asset{" "}
                  <StageDot
                    width={width > 800 ? "1rem" : "8px"}
                    src={stageDot}
                    alt="stageDot"
                  />
                </>
              }
              desc={
                <>
                  세상의 자본 구조를 재편하는 기술 기반 혁신 금융의 탄생,
                  <br /> 우리는 그 혁신의 맨 앞에서 변화의 가치를 올바르게
                  이끌고 효과적으로 알립니다.
                  <br /> 대한민국 블록체인, 디지털자산 전문 마케팅 0.5세대의
                  <br />
                  Attractive Impression으로 프로젝트의 가치를 극대화 하세요.
                </>
              }
              mobileDesc={
                <>
                  세상의 자본 구조를 재편하는 기술 기반 혁신 금융의 탄생, 우리는
                  그 혁신의 맨 앞에서 변화의 가치를 올바르게 이끌고 효과적으로
                  알립니다.
                  <br /> 대한민국 블록체인, 디지털자산 전문 마케팅 0.5세대의
                  Attractive Impression으로 프로젝트의 가치를 극대화 하세요.
                </>
              }
              hash=" #블록체인 #디지털자산 #ICO #IEO #IDO #NFT #커뮤니티"
            />
          </Fade>
          <Grid margin="13rem 0 14rem">
            <Fade bottom>
              <TextCard
                width={width}
                position="right"
                title={
                  <>
                    Campaign for the overall industry{" "}
                    <StageDot
                      width={width > 800 ? "1rem" : "8px"}
                      src={stageDot}
                      alt="stageDot"
                    />
                  </>
                }
                desc={
                  <>
                    산업을 아우르는 디지털 마케팅 캠페인을 기획하고 집행합니다.
                    <br />
                    마케팅 캠페인은 곧 매출증대로 향하는 길이어야 합니다.
                    <br /> 브랜딩, 고객확보를 넘어 전환까지, 제대로 된
                    목표지향적 마케팅 전략을 수립하고
                    <br /> 혁신적인 마케팅 활동을 통해 놀라운 결과를 불러옵니다.
                    <br />
                    퍼즐랩과 함께 소비자가 적극적으로 찾아와 열광하는 브랜드
                    커뮤니케이션 파워를 만드세요.
                  </>
                }
                mobileDesc={
                  <>
                    산업을 아우르는 디지털 마케팅 캠페인을 기획하고 집행합니다.
                    마케팅 캠페인은 곧 매출증대로 향하는 길이어야 합니다.
                    브랜딩, 고객확보를 넘어 전환까지, 제대로 된 목표지향적
                    마케팅 전략을 수립하고 혁신적인 마케팅 활동을 통해 놀라운
                    결과를 불러옵니다.
                    <br /> 퍼즐랩과 함께 소비자가 적극적으로 찾아와 열광하는
                    브랜드 커뮤니케이션 파워를 만드세요.
                  </>
                }
                hash="#통합디지털마케팅 #브랜딩 #광고 #크리에이티브 #PR커뮤니케이션"
              />
            </Fade>
          </Grid>
        </Content>
      </Bg>
    </Position>
  );
};

const Title = styled.span`
  ${({ theme }) => theme.font(36, 900, 38, 0.01)}

  background-color:${({ theme }) => theme.color.orange};
  padding: 0.25rem 1.25rem;

  @media (max-width: 450px) {
    font-size: 4rem;
    line-height: 5rem;

    padding: 1rem 5rem;
  }
`;

const Content = styled(Grids)`
  padding: 14rem 17%;

  @media (max-width: 450px) {
    padding: 16rem 6rem 18rem;
  }
`;

const StageDot = styled.img`
  width: ${(props) => props.width};
`;

export default StageContent;
