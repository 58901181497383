import React from "react";
import styled from "styled-components";

const TextBox = ({ children, right }) => {
  const styles = { right };

  return <TextDirection {...styles}>{children}</TextDirection>;
};

export const TextDirection = styled.div`
  display: flex;
  justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  text-align: ${(props) => (props.right ? "end" : "start")};
  align-items: center;

  @media (max-width: 450px) {
    justify-content: center;
    text-align: start;
  }
`;

export default TextBox;
