import React from "react";
import styled from "styled-components";
import { FadeIn, FadeOut } from "../../animation";
import { Grid } from "../../elements";
import { Grids } from "../../elements/Grid";
import { closeBk } from "../../img";
import { Portal } from "..";

const Modal = ({ handleCloseModal, fadeOut }) => {
  return (
    <Portal>
      <ModalContainer>
        <ModalWindow ani={fadeOut}>
          <ModalContent>
            <Grid between>
              <Title>개인정보 수집•이용 동의 (필수)</Title>
              <Grid onClick={() => handleCloseModal()} pointer>
                <img src={closeBk} alt="closeBtn" />
              </Grid>
            </Grid>

            <Line />

            <TextBox column>
              <p>
                ㈜퍼즐랩(이하“회사”)은 이용자의 개인정보를 중요시하며,
                “정보통신망 이용촉진 및 정보보호에 관한 법률” 및
                “개인정보보호법”을 준수하고 있습니다. 회사는 아래와 같이
                개인정보의 수집·이용, 고유식별정보의 수집·이용, 제3자 제공에
                관한 내용을 관계 법령에 따라 고지 하오니 동의해주시기 바랍니다.
              </p>
              <p>
                ■ 개인정보의 수집 및 이용
                <br /> (1) 개인정보 수집 항목
                <br /> - 기업명, 담당자명, 부서명, 연락처, 예산범위, 의뢰내용
              </p>
              <p>
                (2)개인정보 수집 및 이용 목적 <br />- 서비스 제공을 위한 정보
                활용 : 개인 식별, 온라인 견적 제공
              </p>
              <p>
                (3)개인정보 보유 및 이용 기간 <br />- 프로젝트 의뢰 문의(온라인
                견적 요청)에 대한 서비스 제공 및 문의 내역 확인을 위해 문의
                접수일로부터 1년간 보유 및 이용합니다. 다만, 이용자가 개인정보의
                삭제를 요청하는 경우에는 지체 없이 해당 개인정보를 파기하고
                있습니다.
              </p>
              <p>
                (4)이용자는 개인정보 수집 및 이용과 관련하여 동의를 거부할
                권리가 있으며, 동의거부 시 프로젝트 의뢰 문의에 제약사항이
                발생할 수 있습니다.
              </p>
            </TextBox>
          </ModalContent>
        </ModalWindow>
      </ModalContainer>
    </Portal>
  );
};

const ModalContainer = styled.div`
  height: 100%;
  width: 100vw;
  ${({ theme }) => theme.flexBox("center", "center")}

  position: fixed;
  left: 0;
  top: 0;

  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalWindow = styled.div`
  ${({ theme }) => theme.flexBox("center", "center")};
  background-color: ${({ theme }) => theme.color.white};

  width: 55%;
  max-width: 66.25rem;
  height: auto;

  position: absolute;
  left: 50%;
  top: 50%;

  overflow-y: scroll;
  transform: translate(-50%, -50%);

  animation: ${(props) => (props.ani ? FadeOut : FadeIn)} 0.5s linear;

  @media (max-width: 450px) {
    max-width: calc(100% - 12rem);
    width: 100%;
    height: 70vh;
  }
`;

const ModalContent = styled(Grids)`
  width: 100%;
  height: 100%;
  padding: 2.8rem 3.5rem;

  @media (max-width: 450px) {
    padding: 6rem 4rem 10rem;

    & img {
      width: 3rem;
    }
  }
`;

const Title = styled.p`
  ${({ theme }) => theme.font(32, 700, 38.41, 0.01, theme.color.black)};

  @media (max-width: 450px) {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

const Line = styled.div`
  border: 0.5px solid ${({ theme }) => theme.color.darkGray};
  margin: 2rem 0;

  @media (max-width: 450px) {
    margin: 4rem 0;
  }
`;

const TextBox = styled(Grids)`
  gap: 2rem;

  & > p {
    ${({ theme }) => theme.font(20, 400, 34, 0.03, theme.color.lightGray)};

    &:last-child {
      margin-bottom: 3.5rem;
    }
  }

  @media (max-width: 450px) {
    gap: 10rem;

    & > p {
      gap: 10rem;
      font-size: 3.5rem;
      line-height: 6rem;
    }
  }
`;

export default Modal;
