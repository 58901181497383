import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Grid } from "../elements";
import { Grids } from "../elements/Grid";
import { bgBlack } from "../img";
import { Modal, Map } from "../component";
import emailjs from "@emailjs/browser";
import { Fade } from "react-reveal";
import { MainTitle } from "../elements/Font";
import { theme } from "../component/theme";

const emailValidation =
  /^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,6}$/i;

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const [input, setInput] = useState({
    request: "",
    email: "",
    checkbox: false,
  });

  const [isDisabled, setDisabled] = useState(false);

  const P_key = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
  const t_id = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const s_id = process.env.REACT_APP_EMAIL_SERVICE_ID;

  const handleCloseModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      setFadeOut(false);
      setShowModal(false);
    }, 450);
  };

  const validationCheck = () => {
    const { request, email, checkbox } = input;

    if (!request) {
      return alert("입력하신 내용을 확인해 주세요");
    }

    if (!emailValidation.test(email)) {
      return alert("올바른 이메일 형식이 아닙니다.");
    }

    if (!checkbox) {
      return alert("개인 정보 수집 이용에 동의해주세요.");
    }

    return true;
  };

  const sendEmail = async (e) => {
    const { request, email } = input;

    if (!validationCheck()) {
      return;
    }

    setDisabled(true);

    const payload = {
      request,
      email,
    };

    const { status, text } = await emailjs.send(s_id, t_id, payload, P_key);

    if (status === 200 && text === "OK") {
      alert("문의가 완료 되었습니다");

      setInput({
        email: "",
        request: "",
        checkbox: false,
      });

      setDisabled(false);

      return;
    }

    setDisabled(false);
    return alert("오류가 발생했습니다. 다시 시도 해주세요");
  };

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setInput({
        ...input,
        [name]: value === "on" ? !input.checkbox : value || value,
      });
    },
    [input]
  );

  return (
    <>
      {showModal && (
        <Modal handleCloseModal={handleCloseModal} fadeOut={fadeOut} />
      )}
      <ContactContainer path={bgBlack}>
        <Container>
          <Fade top>
            <MainTitle color={theme.color.orange}>Contact us</MainTitle>
          </Fade>

          <Fade top>
            <Address column>
              <p>서울 강남구 테헤란로 63길 11 이노센스 BD, B1</p>
              <p>Teheran-ro, Gangnam-gu, Seoul, Korea (06162)</p>
            </Address>
          </Fade>
          <Fade bottom>
            <ContactContent>
              <Grid>
                <Map />
              </Grid>
              <Grid column width="50%" gap="2.5rem" margin="0 0 0 1.5rem">
                <TextArea
                  name="request"
                  value={input.request}
                  placeholder="문의사항"
                  type="text"
                  maxLength="1000"
                  onChange={(e) => onChange(e)}
                />
                <Input
                  name="email"
                  value={input.email}
                  placeholder="E-mail"
                  type="email"
                  maxLength="55"
                  onChange={(e) => onChange(e)}
                  required
                />
                <AgreeBox flex>
                  <Checkbox
                    type="checkbox"
                    name="checkbox"
                    checked={input.checkbox}
                    onChange={(e) => onChange(e)}
                  />
                  <Grid column gap="1rem">
                    <Grid flex textCenter>
                      <span onClick={() => setShowModal(true)}>
                        개인정보 수집·이용동의
                      </span>
                      <p>(필수)</p>
                    </Grid>
                    <p>
                      문의를 위한 개인정보 수집·이용 안내를 확인하였으며, 내용에
                      동의합니다.
                    </p>
                  </Grid>
                </AgreeBox>
              </Grid>
            </ContactContent>
          </Fade>
          <Fade>
            <Grid flex justEnd margin="3.5rem 0 0">
              <Btn disabled={isDisabled} onClick={() => sendEmail()}>
                문의하기
              </Btn>
            </Grid>
          </Fade>
        </Container>
      </ContactContainer>
    </>
  );
};

const ContactContainer = styled.section`
  background-image: url(${(props) => props.path});
  background-repeat: no-repeat;
  background-size: cover;

  padding: 296px 17% 200px;

  @media (max-width: 450px) {
    padding: 120px 6rem 48px;
  }
`;

const Container = styled.div`
  max-width: 1272px;
  margin: 0 auto;
`;

const Address = styled(Grids)`
  gap: 1rem;
  margin: 2.5rem 0 2.8rem;

  & > p:nth-child(1) {
    ${({ theme }) => theme.font(36, 500, 43.21, 0.01, theme.color.white)}
  }

  & > p:nth-child(2) {
    ${({ theme }) => theme.font(28, 400, 33.61, 0.01, theme.color.white)}
  }

  @media (max-width: 800px) {
    gap: 2rem;
    margin-bottom: 6rem;

    & > p:nth-child(1) {
      font-size: 4rem;
      line-height: 4.5rem;
    }

    & > p:nth-child(2) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }
`;

const ContactContent = styled(Grids)`
  display: flex;
  width: 100%;
  margin: 0 1.5rem 0 0;

  & > div:nth-child(1) {
    width: 50%;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 6rem;

    & > div:nth-child(1) {
      width: 100%;
    }

    & > div:nth-child(2) {
      width: 100%;
      gap: 4rem;
      margin: 0;

      & textarea {
        font-size: 3.5rem;
        line-height: 4rem;
        height: 46rem;
        padding: 4rem 6rem;
      }

      & input[type="email"] {
        height: 100%;
        padding: 4rem 6rem;
        font-size: 3.5rem;
        line-height: 4rem;
        letter-spacing: -0.045em;

        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`;

const AgreeBox = styled(Grids)`
  & > div > div > p {
    ${({ theme }) => theme.font(24, 400, 28.8, 0.045, theme.color.white)}
  }

  & > div > div > span {
    ${({ theme }) => theme.font(24, 700, 28.8, 0.045, theme.color.white)}

    text-decoration: underline;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  & > div > p {
    ${({ theme }) => theme.font(20, 400, 24, 0.045, theme.color.darkGray)}
  }

  @media (max-width: 800px) {
    margin-top: 1.5rem;
    position: relative;

    & > div > div {
      margin-bottom: 4rem;
    }

    & > div > div > span {
      font-size: 3rem;
      line-height: 4rem;
      margin-right: 1rem;
    }
    & > div > div > p {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    & > div > p {
      font-size: 3rem;
      line-height: 5rem;
    }
  }
`;

const Btn = styled.button`
  ${({ theme }) => theme.font(24, 700, 28.8, 0.045, theme.color.orange)}
  ${({ theme }) => theme.flexBox("center", "center")}

  padding: 1.25rem 6.875rem;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.orange};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.orange};
  }

  &:disabled {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.orange};
    color: ${({ theme }) => theme.color.orange};
  }

  @media (max-width: 450px) {
    margin-top: 2.5rem;
    font-size: 3.5rem;
    line-height: 4.25rem;
    padding: 3rem 12rem;
  }
`;

const TextArea = styled.textarea`
  ${({ theme }) => theme.font(20, 400, 24, 0.045, theme.color.white)}
  border: 1px solid ${({ theme }) => theme.color.white};
  padding: 1.5rem 2rem;
  background-color: transparent;

  resize: none;
  word-break: break-all;
  height: 23rem;
`;

const Input = styled.input`
  ${({ theme }) => theme.font(20, 400, 24, 0.045, theme.color.white)}
  border: 1px solid ${({ theme }) => theme.color.white};

  padding: 1.5rem 2rem;
  background-color: transparent;

  word-break: break-all;
`;

const Checkbox = styled.input`
  min-width: 12px;
  min-height: 12px;

  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1.25rem;

  cursor: pointer;

  @media (max-width: 450px) {
    --size: 12px;
    width: var(---size);
    height: var(---size);
    margin-right: 2.5rem;
  }
`;

export default Contact;
